/* You can add global styles to this file, and also import other style files */

@import url(assets/css/_fonts.css);

html,
body {
	height: 100%;
}
body {
	margin: 0;
}

:root {
	--primary-background-color: #181818;
	--secondary-background-color: #151515;
	--text-color-primary: #ffffff;
	--border-color-primary: #1e1e1e;
	--error-color: #ff2328;
	--button-background-success: #00825c;
	--button-background-success-border: #026a4b;
	--hitl-color: #ff811d;
	--algo-color: #00825c;
	--card-color: #1a1a1a;
	--selected-item: #242424;
	--toolbar-color: #131313;
	--username-color: #3e3e3e;
	--secundary-header-color: #161616;
	--cell-color: #1d1d1d;

	/* Font weights */
	--fw-thin: 100;
	--fw-light: 300;
	--fw-light-regular: 400;
	--fw-regular: 500;
	--fw-bold: 700;
	--fw-black: 900;

	/* Font sizes */
	--font-xs: 12px;
	--font-sm: 14px;
	--primary-fs: 16px;
	--font-md: 18px;
	--font-lg: 19px;
	--font-xlg: 20px;
	--font-h1: 36px;
}

.backdropBackground mat-dialog-container {
	background-color: transparent;
}
