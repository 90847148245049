// Custom Theming for Angular Material
@use '~@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$hitl-primary: mat.define-palette(mat.$indigo-palette);
$hitl-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$hitl-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$hitl-theme: mat.define-light-theme($hitl-primary, $hitl-accent, $hitl-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($hitl-theme);

@include mat.core();

$my-app-primary: mat.define-palette(mat.$teal-palette);
$my-app-accent: mat.define-palette(mat.$deep-orange-palette);
$my-app-warn: mat.define-palette(mat.$red-palette);
$my-app-theme: mat.define-light-theme(
	$my-app-primary,
	$my-app-accent,
	$my-app-warn
);
@include mat.all-component-themes($my-app-theme);

$md-custom-toggle-colors: (
	50: #e8f6e9,
	100: #c5e9c8,
	200: #9fdaa4,
	300: #79cb7f,
	400: #5cc063,
	500: #3fb548,
	600: #39ae41,
	700: #00825c,
	800: #00825c,
	900: #00825c,
	A100: #c6ffc9,
	A200: #00825c,
	A400: #00825c,
	A700: #00825c,
	contrast: (
		50: #da3732,
		100: #da3732,
		200: #da3732,
		300: #da3732,
		400: #da3732,
		500: #da3732,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #da3732,
		A200: #da3732,
		A400: #da3732,
		A700: #da3732
	)
);

$custom-toggle-theme: mat.define-dark-theme(
	mat.define-palette(mat.$pink-palette),
	mat.define-palette($md-custom-toggle-colors)
);

@include mat.slide-toggle-theme($custom-toggle-theme);

$dark-primary: mat.define-palette(mat.$teal-palette, 700, 500, 900);
$dark-accent: mat.define-palette($md-custom-toggle-colors, A200, A100, A400);
$dark-warn: mat.define-palette(mat.$red-palette);

$dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);

@include mat.all-component-themes($dark-theme);

.snack-error {
	background-color: rgb(243, 119, 33);
}

.snack-success {
	background-color: rgb(0, 128, 98);
	color: white;
}

.checkout-style {
	background-color: rgb(243, 119, 33);
}

.error {
	color: #da3732;
}

$custom-typography: mat.define-typography-config(
	$font-family: 'TrasandinaW03'
);
@include mat.core($custom-typography);
