@font-face {
	font-family: 'TrasandinaW03-Ultra';
	src: url('../fonts/Ultra/TrasandinaW03-Ultra.woff2') format('woff2'),
		url('../fonts/Ultra/TrasandinaW03-Ultra.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'TrasandinaW03';
	src: url('../fonts/Thin/TrasandinaW03-Thin.woff2') format('woff2'),
		url('../fonts/Thin/TrasandinaW03-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'TrasandinaW03';
	src: url('../fonts/Thin_Italic/TrasandinaW03-ThinItalic.woff2')
			format('woff2'),
		url('../fonts/Thin_Italic/TrasandinaW03-ThinItalic.woff') format('woff');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'TrasandinaW03';
	src: url('../fonts/Light/TrasandinaW03-Light.woff2') format('woff2'),
		url('../fonts/Light/TrasandinaW03-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'TrasandinaW03';
	src: url('../fonts/Black/TrasandinaW03-Black.woff2') format('woff2'),
		url('../fonts/Black/TrasandinaW03-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'TrasandinaW03';
	src: url('../fonts/Regular/TrasandinaW03-Regular.woff2') format('woff2'),
		url('../fonts/Regular/TrasandinaW03-Regular.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'TrasandinaW03-Book';
	src: url('../fonts/Book/TrasandinaW03-Book.woff2') format('woff2'),
		url('../fonts/Book/TrasandinaW03-Book.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'TrasandinaW03';
	src: url('../fonts/Bold/TrasandinaW03-Bold.woff2') format('woff2'),
		url('../fonts/Bold/TrasandinaW03-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'TrasandinaW03';
	src: url('../fonts/Medium/TrasandinaW03-Medium.woff2') format('woff2'),
		url('../fonts/Medium/TrasandinaW03-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'TrasandinaW03';
	src: url('../fonts/Extra_Light/TrasandinaW03-ExtraLight.woff2')
			format('woff2'),
		url('../fonts/Extra_Light/TrasandinaW03-ExtraLight.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}
